@tailwind base;
@tailwind components;
@tailwind utilities;

/* scrollbar */
html {
  scrollbar-color: #010a0f #e5e5e5;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-thumb {
  background: #010a0f;
}

html::-webkit-scrollbar-thumb:hover {
  background: #010a0f;
}

html::-webkit-scrollbar-track {
  background: #e5e5e5;
}
